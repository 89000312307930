import { useCallback, useEffect, useState } from "react";
import { Col, Container, Row, UncontrolledAlert } from "reactstrap";
import SummaryCard from "./SummaryCard";
import {
  getTransactionCountAnalytics,
  getNewCustomerCountAnalytics,
  getRepeatCustomerCountAnalytics,
  getSalesSumAnalytics,
  getTotalRepeatCustomerCountAnalytics,
  getTotalTransactionCountAnalytics,
  getTotalSalesSumAnalytics
} from "../../../services";
import { DateRange } from "../../constants/dateRange";
import Loader from "../../Common/Loader";
import {
  TTransactionCountReportResponse,
  TNewCustomerCountReportResponse,
  TRepeatCustomerCountReportResponse,
  TSalesSumResponse
} from "@shoutout-labs/market_buzz_crm_types";
import { useAuthStore, useCustomersStore } from "../../../Store/store";
import { Utility } from "../../../utils";

const today = new Date();
const start = new Date();
const compareStartDateDeafult = new Date();
const yesterday = new Date();
yesterday.setDate(today.getDate() - 1);
compareStartDateDeafult.setDate(today.getDate() - 56);
const compareEndDateDefault = new Date();
start.setDate(today.getDate() - 28);
compareStartDateDeafult.setDate(today.getDate() - 56);
compareEndDateDefault.setDate(today.getDate() - 29);

const DashboardPage = () => {
  document.title = "Dashboard | MarketBuzz";

  const [selectedFilter, setSelectedFilter] = useState(DateRange.LAST_28_DAYS);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [transactionAnalytics, setTransactionAnalytics] =
    useState<TTransactionCountReportResponse>({
      count: 0,
      compareToCount: 0,
      comparisonPercentage: 0
    });
  const [salesAnalytics, setSalesAnalytics] = useState<TSalesSumResponse>({
    sum: 0,
    compareToSum: 0,
    comparisonPercentage: 0
  });
  const [newCustomerAnalytics, setNewCustomerAnalytics] =
    useState<TNewCustomerCountReportResponse>({
      count: 0,
      compareToCount: 0,
      comparisonPercentage: 0
    });
  const [returnCustomerAnalytics, setReturnCustomerAnalytics] =
    useState<TRepeatCustomerCountReportResponse>({
      count: 0,
      compareToCount: 0,
      comparisonPercentage: 0
    });
  const [startDate, setStartDate] = useState<string>(
    start.toISOString().split("T")[0]
  );
  const [endDate, setEndDate] = useState<string>(
    yesterday.toISOString().split("T")[0]
  );
  const [compareStartDate, setCompareStartDate] = useState<string>(
    compareStartDateDeafult.toISOString().split("T")[0]
  );
  const [compareEndDate, setCompareEndDate] = useState<string>(
    compareEndDateDefault.toISOString().split("T")[0]
  );
  const [selectedRange, setSelectedRange] = useState<number>(28);
  const [isLoggedIn] = useAuthStore((state) => [state.isLoggedIn]);
  const [totalCustomers] = useCustomersStore((state) => [state.totalCustomers]);

  const handleFilterChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const filter = e.target.value;
      setSelectedFilter(filter as DateRange);

      if (filter === DateRange.ALL_TIME) {
        setStartDate("");
        setEndDate("");
        setCompareEndDate("");
        setCompareStartDate("");
        setSelectedRange(-1);
      } else {
        const selectedDateRange = Utility.getDateRange(filter);
        const numericValue = Utility.extractNumericValueFromString(filter);
        setStartDate(selectedDateRange?.startDate);
        setEndDate(selectedDateRange?.endDate);
        setCompareEndDate(selectedDateRange?.compareEndDate);
        setCompareStartDate(selectedDateRange?.compareStartDate);
        setSelectedRange(numericValue);
      }
    },
    [
      setSelectedFilter,
      setStartDate,
      setEndDate,
      setCompareEndDate,
      setCompareStartDate,
      setSelectedRange
    ]
  );

  const loadAnalyticsData = useCallback(async () => {
    const queryParams = {
      fromDate: startDate,
      toDate: endDate,
      compareFromDate: compareStartDate,
      compareToDate: compareEndDate
    };

    try {
      setIsLoading(true);
      const [
        transactionAnalyticsRes,
        newCustomerAnalyticsRes,
        salesSumRes,
        repeatCustomerAnalyticsRes
      ] = await Promise.all([
        getTransactionCountAnalytics(queryParams),
        getNewCustomerCountAnalytics(queryParams),
        getSalesSumAnalytics(queryParams),
        getRepeatCustomerCountAnalytics(queryParams)
      ]);

      setIsLoading(false);
      setTransactionAnalytics(transactionAnalyticsRes);
      setNewCustomerAnalytics(newCustomerAnalyticsRes);
      setReturnCustomerAnalytics(repeatCustomerAnalyticsRes);
      setSalesAnalytics(salesSumRes);
    } catch (e) {
      console.error("Error when loading dashboard analytics", e);
    } finally {
      setIsLoading(false);
    }
  }, [
    startDate,
    endDate,
    compareEndDate,
    compareStartDate,
    setIsLoading,
    setTransactionAnalytics,
    setNewCustomerAnalytics,
    setReturnCustomerAnalytics,
    setSalesAnalytics
  ]);

  const loadTotalAnalyticsData = useCallback(async () => {
    try {
      setIsLoading(true);
      const [transactionAnalyticsRes, salesSumRes, repeatCustomerAnalyticsRes] =
        await Promise.all([
          getTotalTransactionCountAnalytics(),
          getTotalSalesSumAnalytics(),
          getTotalRepeatCustomerCountAnalytics()
        ]);

      setIsLoading(false);
      setTransactionAnalytics(transactionAnalyticsRes);
      setNewCustomerAnalytics({
        count: totalCustomers,
        compareToCount: 0,
        comparisonPercentage: 0
      });
      setReturnCustomerAnalytics(repeatCustomerAnalyticsRes);
      setSalesAnalytics(salesSumRes);
    } catch (e) {
      console.error(
        "Error when loading dashboard analytics for all time data",
        e
      );
    } finally {
      setIsLoading(false);
    }
  }, [
    setIsLoading,
    setTransactionAnalytics,
    setNewCustomerAnalytics,
    setReturnCustomerAnalytics,
    setSalesAnalytics,
    totalCustomers
  ]);

  useEffect(() => {
    if (isLoggedIn()) {
      if (selectedFilter === DateRange.ALL_TIME) {
        loadTotalAnalyticsData();
      } else {
        loadAnalyticsData();
      }
    }
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [
    startDate,
    endDate,
    compareEndDate,
    compareStartDate,
    selectedFilter,
    isLoggedIn
  ]);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <UncontrolledAlert color="info" className="alert-border-left">
            Your dashboard highlights the past 3 months for streamlined,
            actionable insights.
          </UncontrolledAlert>
          <Row>
            <Col xxl={10} xl={10} lg={10} md={10} sm={8}>
              <h4 className="mb-3">Dashboard</h4>
            </Col>
            <Col xxl={2} xl={2} lg={2} md={2} sm={4}>
              <select
                id="dateFilter"
                className="form-select form-select-sm"
                value={selectedFilter}
                onChange={handleFilterChange}
              >
                <option value={DateRange.TODAY}>{DateRange.TODAY}</option>
                <option value={DateRange.LAST_7_DAYS}>
                  {DateRange.LAST_7_DAYS}
                </option>
                <option value={DateRange.LAST_28_DAYS}>
                  {DateRange.LAST_28_DAYS}
                </option>
                <option value={DateRange.LAST_90_DAYS}>
                  {DateRange.LAST_90_DAYS}
                </option>
                <option value={DateRange.LAST_365_DAYS}>
                  {DateRange.LAST_365_DAYS}
                </option>
                <option value={DateRange.ALL_TIME}>{DateRange.ALL_TIME}</option>
              </select>
            </Col>
          </Row>
          {isLoading ? (
            <Loader />
          ) : (
            <div>
              <Row>
                <Col xxl={6} xl={6} lg={6} md={6} sm={6}>
                  <SummaryCard
                    title="New Customers"
                    id="new_customers"
                    count={newCustomerAnalytics?.count}
                    percentageValue={
                      newCustomerAnalytics?.comparisonPercentage as number
                    }
                    dateRange={selectedRange as number}
                    isAllowDecimal={false}
                  />
                </Col>
                <Col xxl={6} xl={6} lg={6} md={6} sm={6}>
                  <SummaryCard
                    title="Sales"
                    id="sales"
                    count={salesAnalytics.sum as number}
                    percentageValue={
                      salesAnalytics.comparisonPercentage as number
                    }
                    dateRange={selectedRange as number}
                    isAllowDecimal={true}
                  />
                </Col>
              </Row>
              <Row>
                <Col xxl={6} xl={6} lg={6} md={6} sm={6}>
                  <SummaryCard
                    title="Return Customers"
                    id="return_customers"
                    count={returnCustomerAnalytics.count as number}
                    percentageValue={
                      returnCustomerAnalytics.comparisonPercentage as number
                    }
                    dateRange={selectedRange as number}
                    isAllowDecimal={false}
                  />
                </Col>
                <Col xxl={6} xl={6} lg={6} md={6} sm={6}>
                  <SummaryCard
                    title="Transaction Count"
                    id="transactions"
                    count={transactionAnalytics.count as number}
                    percentageValue={
                      transactionAnalytics.comparisonPercentage as number
                    }
                    dateRange={selectedRange as number}
                    isAllowDecimal={false}
                  />
                </Col>
              </Row>
            </div>
          )}
        </Container>
      </div>
    </>
  );
};

export default DashboardPage;
