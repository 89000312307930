import { useState } from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown
} from "reactstrap";

const CustomerSegmentDropdown = ({ allSegments, toggleTab }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);
  return (
    <>
      <UncontrolledDropdown
        isOpen={dropdownOpen}
        toggle={toggleDropdown}
        className="mb-2"
      >
        <DropdownToggle color="light">
          Select Customer Segment <i className="mdi mdi-chevron-down"></i>
        </DropdownToggle>
        <DropdownMenu>
          {allSegments?.length > 0 &&
            allSegments.map((segment) => (
              <DropdownItem
                key={segment.id}
                onClick={() => toggleTab(segment.id)}
              >
                {segment.name}
              </DropdownItem>
            ))}
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  );
};

export default CustomerSegmentDropdown;
