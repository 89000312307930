import { useCallback, useMemo, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  FormText,
  Input,
  FormGroup
} from "reactstrap";
import { createCampaign } from "../../../services";
import {
  CampaignStatus,
  CampaignChannel,
  CampaignType
} from "../../../Components/constants/campaign";
import { ContentState, EditorState } from "draft-js";
import MessageTextArea from "../../../pages/BaseUi/UiModals/MessageTextArea";
import BottomContainer from "../../../pages/BaseUi/UiModals/BottomContainer";
import SMSUtils from "@shoutout-labs/marketbuzz-utils/dist/messages/sms.utils";
import { useAuthStore } from "../../../Store/store";
import { Utility } from "../../../utils";
import { useQuery } from "@tanstack/react-query";
import { getSegments } from "../../../services";

import { useMutation } from "@tanstack/react-query";
import { TCampaignCreateRequest } from "@shoutout-labs/market_buzz_crm_types";
import { toast } from "react-toastify";
import { PreDefinedSegments } from "../Customers/Constants";
import CustomerSegmentDropdown from "../Customers/CustomerSegmentDropdown";
import { filterConfig } from "../Customers/CustomersFilterConfig";
import "./CampaignCreateModal.scss";

interface UiCampaignEditModalProps {
  fade: boolean | undefined;
  showModal: boolean;
  closeModal: () => void;
}

const CampaignCreateModal = ({
  fade,
  showModal,
  closeModal
}: UiCampaignEditModalProps) => {
  const [message, setMessage] = useState("");
  const [name, setName] = useState("");
  const [selectedSegment, setSelectedSegment] = useState("");
  const [selectedSegmentName, setSelectedSegmentName] = useState("");
  const [limitSegments, setLimitSegments] = useState(100);
  const [skipSegments, setSkipSegments] = useState(0);
  const [isLoggedIn] = useAuthStore((state) => [state.isLoggedIn]);

  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(ContentState.createFromText(message))
  );

  const handleInputChange = (newEditorState: EditorState) => {
    setEditorState(newEditorState);
    const contentState = newEditorState.getCurrentContent();
    const rawText = contentState.getPlainText();
    setMessage(rawText);
  };

  const charCount = useMemo(() => message.length, [message]);

  const pageCount = useMemo(
    () => SMSUtils.getPagesCount(message, 20),
    [message]
  );

  const mutation = useMutation({
    mutationFn: (payload: TCampaignCreateRequest) => {
      return createCampaign(payload);
    },
    onSuccess: () => {
      toast.success("Campaign is created");
      closeModal();
    },
    onError: (error) => {
      console.error(error);
    }
  });

  const loadSegmentsData = () => {
    const queryObj = {
      limit: limitSegments,
      skip: skipSegments
    };
    return getSegments(queryObj);
  };

  const { data: segmentsData, isLoading } = useQuery<any>({
    queryKey: ["segments", skipSegments],
    queryFn: loadSegmentsData,
    enabled: isLoggedIn(),
    refetchOnWindowFocus: false
  });

  const allSegments = useMemo(() => {
    if (!isLoading && segmentsData?.items.length > 0) {
      return [...PreDefinedSegments, ...segmentsData?.items];
    } else {
      return [...PreDefinedSegments];
    }
  }, [segmentsData, isLoading]);

  const onChangeCampaignName = useCallback(
    (e) => setName(e.target.value),
    [setName]
  );

  const onSelectSegment = useCallback(
    (segmentId) => {
      setSelectedSegment(segmentId);
      const segmentData = allSegments?.find(
        (segment) => segment?.id === segmentId
      );
      setSelectedSegmentName(segmentData?.name);
    },
    [setSelectedSegment, setSelectedSegmentName, allSegments]
  );

  const handleCampaignSent = useCallback(async () => {
    const segmentData = allSegments?.find(
      (segment) => segment?.id === selectedSegment
    );

    const customerFilters = Utility.getMongoDBQuery(
      segmentData?.filter,
      filterConfig
    );
    const payload = {
      content: message,
      name: name,
      channel: CampaignChannel.SMS,
      status: CampaignStatus.DRAFT,
      type: CampaignType.BROADCAST,
      segmentId: selectedSegment,
      filter: customerFilters
    };
    mutation.mutate(payload);
  }, [message, name, mutation, selectedSegment, allSegments]);

  return (
    <div>
      <div>
        <Modal isOpen={showModal} toggle={closeModal} fade={fade}>
          <ModalHeader toggle={closeModal} color="primary">
            Start Campaign
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label>
                Camapign Name
                <span className="text-danger">*</span>
              </Label>
              <Input
                type="text"
                placeholder="Enter a filter segment name"
                value={name}
                onChange={onChangeCampaignName}
                required
              />
              {name === "" && (
                <FormText className="text-danger">
                  * Campaign name cannot be empty!
                </FormText>
              )}
            </FormGroup>
            <FormGroup>
              <Label>
                Customer Segment
                <span className="text-danger">*</span>
              </Label>
              <CustomerSegmentDropdown
                allSegments={allSegments}
                toggleTab={onSelectSegment}
              />
              {selectedSegmentName && (
                <p>Selected segment is {selectedSegmentName}</p>
              )}
              <p className="text-info text-justify">
                SMS campaigns are sent only to customers who have provided a
                mobile number and opted in for marketing.
              </p>
            </FormGroup>

            <div className="d-flex justify-content-between align-items-center mb-3">
              <Label>
                Message Text
                <span className="text-danger">*</span>
              </Label>
            </div>
            <MessageTextArea
              editorState={editorState}
              handleInputChange={handleInputChange}
            />
            <BottomContainer
              charCount={charCount}
              pageCount={pageCount}
              message={message}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={handleCampaignSent}
              disabled={
                Utility.hasCustomAttributes(message) ||
                pageCount > 3 ||
                mutation.isPending
              }
            >
              {mutation.isPending ? "Creating..." : "Start Campaign Now"}
            </Button>{" "}
            <Button
              color="secondary"
              onClick={closeModal}
              disabled={mutation.isPending}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </div>
  );
};

export default CampaignCreateModal;
