import { useState, useEffect, useCallback } from "react";
import { Card, CardBody, Table } from "reactstrap";
import { useAuthStore } from "../../../Store/store";
import { TInvoiceModelJSON } from "@shoutout-labs/market_buzz_crm_types";
import { getInvoices } from "../../../services";
import Loader from "../../Common/Loader";

const InvoiceList = () => {
  const [invoices, setInvoices] = useState<TInvoiceModelJSON[]>([]);
  const [isLoggedIn] = useAuthStore((state) => [state.isLoggedIn]);
  const [loading, setLoading] = useState<boolean>(false);

  const loadInvoiceData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await getInvoices();
      setInvoices(response.items);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  }, [setInvoices]);

  useEffect(() => {
    if (isLoggedIn()) {
      loadInvoiceData();
    }
  }, [isLoggedIn, loadInvoiceData]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="table-responsive table-card mt-4 p-3">
          <Card className="border shadow-none">
            <CardBody>
              <h6 className="mb-4 mt-2">SMS Package Purchase History</h6>
              <Table className="align-middle table-nowrap mb-0">
                <thead className="table-light">
                  <tr>
                    <th scope="col">Invoice ID</th>
                    <th scope="col">Purchase Date</th>
                    <th scope="col">Buzz Credits</th>
                    <th scope="col">Cost</th>
                  </tr>
                </thead>
                {invoices.length > 0 ? (
                  <tbody>
                    {invoices.map((data) => (
                      <tr key={data.referenceId}>
                        <td>{data.id}</td>
                        <td>{new Date(data.createdOn).toLocaleDateString()}</td>
                        <td>{data.credits}</td>
                        <td>${data.amount}</td>
                      </tr>
                    ))}
                  </tbody>
                ) : (
                  <tr className="mt-5">
                    <td className="text-center" colSpan={4}>
                      No data found
                    </td>
                  </tr>
                )}
              </Table>
            </CardBody>
          </Card>
        </div>
      )}
    </>
  );
};

export default InvoiceList;
