import React from "react";
import SMSUtils from "@shoutout-labs/marketbuzz-utils/dist/messages/sms.utils";

interface BottomContainerProps {
  charCount: number;
  pageCount: number;
  message: string;
}

const BottomContainer = ({
  charCount,
  pageCount,
  message
}: BottomContainerProps) => (
  <div className="bottom-container">
    <div className="bottom-container-text">
      <div className="counter gap-3">Page Count: {pageCount}</div>
      <div className="counter">
        {charCount}/{SMSUtils.hasUnicode(message) ? 181 : 439}
      </div>
    </div>
    <p className="fst-italic text-primary">
      Modify the blue highlighted fields as desired.
    </p>
    <p className="fst-italic text-info">
      Your SMS includes a concise 20-character opt-out message.
    </p>
    <p className="fst-italic text-warning">
      {charCount > (SMSUtils.hasUnicode(message) ? 181 : 439) && (
        <span>
          Messages over {SMSUtils.hasUnicode(message) ? 181 : 439} characters
          more. Keep it brief on charges.
        </span>
      )}
    </p>
  </div>
);

export default BottomContainer;
