import { useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  UncontrolledAlert
} from "reactstrap";
import classnames from "classnames";
import UiCards from "../UiCards/UiCards";
import CampaignHistoryTable from "../../../Components/Modules/Campaigns/CampaignHistoryTable";
import { getCustomersCount } from "../../../services";
import { useAuthStore } from "../../../Store/store";
import { useQuery } from "@tanstack/react-query";

const UiTabs = () => {
  const [activeTab, setactiveTab] = useState("1");
  const [isLoggedIn] = useAuthStore((state) => [state.isLoggedIn]);
  const toggle = (tab: any) => {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  };

  const { data: totalCustomers, isLoading } = useQuery<number>({
    queryKey: ["customerCount"],
    queryFn: async () => {
      const queryObj = {
        marketingAllowed: true,
        isRequiredPhoneNumber: true,
        hasTransactions: true
      };
      const response = await getCustomersCount(queryObj);
      return response?.count;
    },
    enabled: isLoggedIn,
    refetchOnWindowFocus: false
  });

  return (
    <>
      <Row>
        <Col xxl={12}>
          <Card>
            <CardBody>
              <Nav tabs className="nav-tabs mb-3">
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({ active: activeTab === "1" })}
                    onClick={() => {
                      toggle("1");
                    }}
                  >
                    Campaign Templates
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({ active: activeTab === "2" })}
                    onClick={() => {
                      toggle("2");
                    }}
                  >
                    Campaign History
                  </NavLink>
                </NavItem>
              </Nav>

              <TabContent activeTab={activeTab} className="text-muted">
                <TabPane tabId="1" id="templates">
                  {totalCustomers === 0 && (
                    <UncontrolledAlert
                      color="warning"
                      className="alert-border-left"
                    >
                      SMS campaigns can only be sent to customers who have
                      provided a mobile number and opted in for marketing. Those
                      without this information will not receive SMS.
                    </UncontrolledAlert>
                  )}
                  <UiCards toggle={toggle} totalCustomers={totalCustomers} />
                </TabPane>
                <TabPane tabId="2" id="history">
                  <CampaignHistoryTable activeTab={activeTab} />
                </TabPane>
              </TabContent>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default UiTabs;
