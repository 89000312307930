import { Container, Row } from "reactstrap";
import CustomersTable from "./CustomersTable";
import Loader from "../../Common/Loader";

import "./CustomerPage.scss";

const CustomerTabComponent = ({
  customersList,
  isLoadingCustomers,
  customersCount,
  isLoadingCustomersCount
}) => {
  document.title = "Customer Tab Component | MarketBuzz";

  const isLoading = isLoadingCustomers || isLoadingCustomersCount;

  return (
    <div className="page-content">
      <Container fluid>
        <Row className="project-wrapper">
          {isLoading ? (
            <Loader />
          ) : (
            <CustomersTable
              customersResults={customersList}
              customersCount={customersCount}
            />
          )}
        </Row>
      </Container>
    </div>
  );
};

export default CustomerTabComponent;
