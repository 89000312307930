import { useState, useEffect, useMemo } from "react";
import {
  Card,
  CardBody,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane
} from "reactstrap";
import classnames from "classnames";
import CustomerTabComponent from "./CustomerTabComponent";
import AllCustomerTabComponent from "./AllCustomerTabComponent";
import { useQuery } from "@tanstack/react-query";
import { getSegments } from "../../../services";
import { useAuthStore, useCustomersStore } from "../../../Store/store";
import {
  CustomersFilterTasks,
  useCustomers,
  useCustomersCount
} from "./context/CustomerDataContext";
import { Utility } from "../../../utils";
import { filterConfig } from "./CustomersFilterConfig";
import { PreDefinedSegments } from "./Constants";
import CustomerSegmentDropdown from "./CustomerSegmentDropdown";

const CustomerTabs = () => {
  const [isLoggedIn] = useAuthStore((state) => [state.isLoggedIn]);

  const [limit, skip, setQueryTask] = useCustomersStore((state) => [
    state.limit,
    state.skip,
    state.setQueryTask,
    state.queryTask
  ]);
  const {
    customers: customersList,
    isLoadingCustomers,
    isFetchingCustomersData
  } = useCustomers();
  const { customersCount, isLoadingCustomersCount, isFetchingCustomersCount } =
    useCustomersCount();

  const [activeTab, setactiveTab] = useState("all");
  const toggleTab = (tab: any) => {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  };

  const [limitSegments, setLimitSegments] = useState(100);
  const [skipSegments, setSkipSegments] = useState(0);

  const loadSegmentsData = () => {
    const queryObj = {
      limit: limitSegments,
      skip: skipSegments
    };
    return getSegments(queryObj);
  };

  const {
    data: segmentsData,
    isLoading,
    refetch
  } = useQuery<any>({
    queryKey: ["segments", skipSegments],
    queryFn: loadSegmentsData,
    enabled: isLoggedIn(),
    refetchOnWindowFocus: false
  });

  const allSegments = useMemo(() => {
    if (!isLoading && segmentsData?.items.length > 0) {
      return segmentsData?.items;
    }
  }, [segmentsData]);

  useEffect(() => {
    if (activeTab !== "all") {
      const currentTab =
        PreDefinedSegments.find((segment) => segment?.id === activeTab) ||
        allSegments?.find((segment) => segment?.id === activeTab);

      const customerFilters = Utility.getMongoDBQuery(
        currentTab?.filter,
        filterConfig
      );

      setQueryTask({
        task: CustomersFilterTasks.filterCustomers,
        query: { filterObj: customerFilters }
      });
    } else {
      setQueryTask({
        task: CustomersFilterTasks.getAllCustomers,
        query: {}
      });
    }
  }, [activeTab]);

  return (
    <>
      <Row>
        <Col xxl={12}>
          <Card>
            <CardBody>
              <Nav tabs className="nav-tabs mb-3">
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTab === "all"
                    })}
                    onClick={() => {
                      toggleTab("all");
                    }}
                  >
                    All Customers
                  </NavLink>
                </NavItem>
                {PreDefinedSegments.map((segment) => (
                  <NavItem key={segment.id}>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTab === segment?.id
                      })}
                      onClick={() => toggleTab(segment?.id)}
                    >
                      {segment?.name}
                    </NavLink>
                  </NavItem>
                ))}
                <CustomerSegmentDropdown
                  allSegments={allSegments}
                  toggleTab={toggleTab}
                />
              </Nav>
              <TabContent activeTab={activeTab} className="text-muted">
                <TabPane tabId="all" id="All Customers">
                  <AllCustomerTabComponent
                    handleSegmentCreationSuccess={refetch}
                    limit={limit}
                    skip={skip}
                    setQueryTask={setQueryTask}
                    customersList={customersList}
                    isLoadingCustomers={isLoadingCustomers}
                    isFetchingCustomersData={isFetchingCustomersData}
                    customersCount={customersCount}
                    isLoadingCustomersCount={isLoadingCustomersCount}
                    isFetchingCustomersCount={isFetchingCustomersCount}
                  />
                </TabPane>

                {PreDefinedSegments.concat(allSegments || []).map((segment) => (
                  <TabPane
                    key={segment.id}
                    tabId={segment.id}
                    id={segment.name}
                  >
                    {segment?.description && (
                      <Card className="border shadow-none">
                        <CardBody>
                          <h6 className="mb-4 mt-2">{segment.name}</h6>
                          {segment?.description}
                        </CardBody>
                      </Card>
                    )}
                    <CustomerTabComponent
                      customersList={customersList}
                      isLoadingCustomers={isLoadingCustomers}
                      customersCount={customersCount}
                      isLoadingCustomersCount={isLoadingCustomersCount}
                    />
                  </TabPane>
                ))}
              </TabContent>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default CustomerTabs;
