import { useState, useCallback } from "react";
import { Table } from "reactstrap";
import Pagination from "../../Common/Pagination";
import { useCustomersStore } from "../../../Store/store";
import { TCustomerModelJSON } from "@shoutout-labs/market_buzz_crm_types";
import UiOffcanvas from "../../../pages/BaseUi/UiOffCanvas/UiOffCanvas";
import { Utility } from "../../../utils";
import "./CustomerTable.scss";

interface CustomersTableProps {
  customersResults: TCustomerModelJSON[];
  customersCount: number;
}

const CustomersTable: React.FC<CustomersTableProps> = ({
  customersResults,
  customersCount
}) => {
  const [limit, skip, setSkip] = useCustomersStore((state) => [
    state.limit,
    state.skip,
    state.setSkip
  ]);

  const [isRight, setIsRight] = useState<boolean>(false);
  const [setSelectedCustomer] = useCustomersStore((state) => [
    state.setSelectedCustomer
  ]);

  const toggleRightCanvas = useCallback(
    (e: any) => {
      setIsRight(!isRight);
      const cusomterId = e.currentTarget.getAttribute("data-id");
      const customer = customersResults.find(
        (customer) => customer?.id === cusomterId
      );
      setSelectedCustomer(customer as TCustomerModelJSON);
    },
    [setIsRight, isRight, customersResults, setSelectedCustomer]
  );

  return (
    <>
      <div className="table-responsive table-card">
        <Table className="align-middle table-nowrap mb-0 customer-table">
          <thead className="table-light">
            <tr>
              <th scope="col">First Name</th>
              <th scope="col">Last Name</th>
              <th scope="col">Email</th>
              <th scope="col">Phone Number</th>
              <th scope="col">Avg. Spend</th>
              <th scope="col" style={{ width: "150px" }}>
                Visits
              </th>
              {/* <th scope="col">Last Activity</th>
    <th scope="col">Marketing Permission</th> */}
            </tr>
          </thead>
          <tbody>
            {customersResults && customersResults?.length > 0 ? (
              customersResults.map((customer) => (
                <tr
                  key={customer.referenceId}
                  onClick={toggleRightCanvas}
                  data-id={customer.id}
                  className=""
                >
                  {/* <td>
                  <div className="d-flex gap-2 align-items-center">
                    <div className="flex-grow-1">{`${customer.firstName} ${customer.lastName}`}</div>
                  </div>
                </td> */}
                  <td>
                    <div className="d-flex gap-2 align-items-center">
                      <div className="flex-grow-1">{customer.firstName}</div>
                    </div>
                  </td>
                  <td>
                    <div className="d-flex gap-2 align-items-center">
                      <div className="flex-grow-1">{customer.lastName}</div>
                    </div>
                  </td>
                  <td>{customer.email}</td>
                  <td>{customer.phoneNumber}</td>
                  <td>
                    ${" "}
                    {Utility.getAvgSpend(
                      (customer?.totalTransactionsCount as number) || 1,
                      (customer?.totalTransactionsSum as number) || 0
                    )}
                  </td>
                  <td>{customer?.totalTransactionsCount}</td>
                  {/* <td>
                  {customer.optIn.marketing.allowed ? (
                    <span className="badge bg-success">Enabled</span>
                  ) : (
                    <span className="badge bg-danger">Disabled</span>
                  )}
                </td> */}
                </tr>
              ))
            ) : (
              <tr className="mt-5">
                <td className="text-center" colSpan={4}>
                  No data found
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      <div className="align-items-center mt-2 row g-3 text-center text-sm-start">
        <div className="col-sm">
          <div className="text-muted">
            Showing{" "}
            <span className="fw-semibold">
              {" "}
              {Math.min(customersCount, skip + limit)}{" "}
            </span>{" "}
            of <span className="fw-semibold">{customersCount}</span> Results
          </div>
        </div>
        <Pagination
          dataCount={customersCount}
          currentPage={skip / limit + 1}
          setCurrentPage={(pageNumber: number) => {
            setSkip(limit * (pageNumber - 1));
          }}
          perPageData={limit}
          // Remove setSkip and loadData fns
          setSkip={() => {}}
          loadData={() => {}}
        />
      </div>
      <UiOffcanvas isRight={isRight} toggleRightCanvas={toggleRightCanvas} />
    </>
  );
};

export default CustomersTable;
